import { IconButton } from '@mui/material';
import { Image } from '@vibTheme/components/_base/Image';
import { Typography } from '@vibTheme/components/_base/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from '@vibTheme/components/_base/Link';
import { getNPSBannerStatus, hideNPSBanner } from '@repository/npsBanner';
import { useState } from 'react';

export const NPSBanner = () => {
  const [show, setShow] = useState(getNPSBannerStatus());

  const hideBanner = () => {
    hideNPSBanner();
    setShow(false);
  };

  if (!show) return null;

  return (
    <div className="flex items-center gap-main relative shadow-small rounded-default p-main mb-main">
      <IconButton className="absolute top-0 right-0" onClick={hideBanner}>
        <CloseIcon />
      </IconButton>

      <Image
        src="/assets/nps-banner-star.svg"
        alt="Participe da pesquisa"
        width="58"
        height="58"
      />

      <div>
        <Typography variant="h3" className="font-semibold">
          Responda à nossa Pesquisa de Satisfação e concorra a brindes
          exclusivos!
        </Typography>
        <Link
          href="https://docs.google.com/forms/d/e/1FAIpQLSfovJYT1YLC64ISGrLMgCcughrqFcdUfWH-vjo_UlwguETiLw/viewform"
          target="_blank"
          onClick={hideBanner}
          unstyled
        >
          <Typography className="text-primary font-semibold">
            Responder agora
          </Typography>
        </Link>
      </div>
    </div>
  );
};
