export const getDefaultMessage = (
  type:
    | 'salesCountInfo'
    | 'salesValueInfo'
    | 'refundsCountInfo'
    | 'refundsValueInfo'
    | 'newUsersInfo',
  newValue: number,
  totalValue: number,
  percentage: number | string,
  newLabel?: string,
  title?: string,
) => {
  switch (type) {
    case 'salesCountInfo':
      return `Suas comunidades tiveram ${newValue} novas vendas no período escolhido, representando ${
        percentage > 1
          ? `um aumento de (${percentage}%)`
          : `uma queda de (${+percentage * -1}%)`
      }  em comparação com o período anterior, que teve ${totalValue} vendas.`;

    case 'salesValueInfo':
      return `Suas comunidades tiveram um valor de vendas de R$${newValue} no período escolhido, representando ${
        percentage > 1
          ? `um aumento de (${percentage}%)`
          : `uma queda de (${+percentage * -1}%)`
      }  em comparação com o período anterior, que teve R$${totalValue} em vendas.`;

    case 'refundsCountInfo':
      return `Suas comunidades tiveram ${newValue} novos reembolsos no período escolhido, representando ${
        percentage > 1
          ? `um aumento de (${percentage}%)`
          : `uma queda de (${+percentage * -1}%)`
      }  em comparação com o período anterior, que teve ${totalValue} reembolsos.`;

    case 'refundsValueInfo':
      return `Suas comunidades tiveram um valor de reembolsos de ${newValue} no período escolhido, representando ${
        percentage > 1
          ? `um aumento de (${percentage}%)`
          : `uma queda de (${+percentage * -1}%)`
      }  em comparação com o período anterior, que teve R$${totalValue} em reembolsos.`;

    case 'newUsersInfo':
      return `Suas comunidades tiveram ${newValue} (${percentage}%)  ${newLabel} no período escolhido, de um total de ${totalValue} ${title}.`;

    default:
      return 'No default message matches this chart type';
  }
};
