const NPS_BANNER_LOCAL_STORAGE_KEY = 'show-nps-banner';

export const getNPSBannerStatus = () => {
  const ls = localStorage.getItem(NPS_BANNER_LOCAL_STORAGE_KEY);
  ls === null && setNPSBannerStatus(true);

  return Boolean(ls === 'true' || ls === null);
};

const setNPSBannerStatus = (bool: boolean) => {
  localStorage.setItem(NPS_BANNER_LOCAL_STORAGE_KEY, JSON.stringify(bool));
};

export const hideNPSBanner = () => {
  setNPSBannerStatus(false);
};
